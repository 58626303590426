import { gql } from "@apollo/client";
import {
  ADDRESS_FRAGMENT,
  ADMINISTRATOR_FRAGMENT,
  ANNOUNCEMENT_FRAGMENT,
  ASSET_FRAGMENT,
  CARD_FRAGMENT,
  CHANNEL_FRAGMENT,
  COMPLIANCE_FRAGMENT,
  COMPLIANCE_QUOTE_DATA_FRAGMENT,
  CUSTOMER_FRAGMENT,
  EMAIL_CATEGORY_FRAGMENT,
  EMAIL_TEMPLATE_FRAGMENT,
  ERROR_RESULT_FRAGMENT,
  ORDER_DETAIL_FRAGMENT,
  ORDER_FRAGMENT,
  PAGE_MENU_FRAGMENT,
  PAYMENT_METHOD_FRAGMENT,
  PICKUP_OPTION_FRAGMENT,
  PRODUCT_OPTION_GROUP_FRAGMENT,
  PRODUCT_VARIANT_FRAGMENT,
  PRODUCT_WITH_OPTIONS_FRAGMENT,
  PRODUCT_WITH_VARIANTS_FRAGMENT,
  PROMOTION_FRAGMENT,
  REFUND_ORDER_FRAGMENT,
  ROLE_FRAGMENT,
  SEASON_FRAGMENT,
  SHIPPING_METHOD_FRAGMENT,
  TAG_FRAGMENT,
} from "./fragments.graphql";

export const ADD_ITEM_TO_ORDER = gql`
  mutation addItemToOrder(
    $orderId: ID!
    $productVariantId: ID!
    $quantity: Int!
    $customFields: OrderLineCustomFieldsInput
  ) {
    addItemToOrder(
      orderId: $orderId
      productVariantId: $productVariantId
      quantity: $quantity
      customFields: $customFields
    ) {
      ...Order
    }
  }
  ${ORDER_FRAGMENT}
`;

export const REMOVE_ITEM_FROM_ORDER = gql`
  mutation RemoveItemFromOrder($orderId: ID!, $orderLineId: ID!) {
    removeOrderLine(orderId: $orderId, orderLineId: $orderLineId) {
      ...Order
      ... on ErrorResult {
        errorCode
        message
      }
    }
  }
  ${ORDER_FRAGMENT}
`;

export const REMOVE_ALL_ORDER_ITEMS = gql`
  mutation RemoveAllOrderItems($orderId: ID!) {
    removeAllOrderLines(orderId: $orderId) {
      ...Order
      ... on ErrorResult {
        errorCode
        message
      }
    }
  }
  ${ORDER_FRAGMENT}
`;

export const CREATE_CUSTOMER_ADDRESS = gql`
  mutation CreateCustomerAddress(
    $customerId: ID!
    $input: CreateAddressInput!
  ) {
    createCustomerAddress(customerId: $customerId, input: $input) {
      ...Address
    }
  }
  ${ADDRESS_FRAGMENT}
`;

export const UPDATE_CUSTOMER_ADDRESS = gql`
  mutation UpdateCustomerAddress($input: UpdateAddressInput!) {
    updateCustomerAddress(input: $input) {
      ...Address
    }
  }
  ${ADDRESS_FRAGMENT}
`;

export const DELETE_CUSTOMER_ADDRESS = gql`
  mutation DeleteCustomerAddress($id: ID!) {
    deleteCustomerAddress(id: $id) {
      success
    }
  }
`;

export const CREATE_CUSTOMER = gql`
  mutation CreateCustomer($input: CreateCustomerInput!, $password: String) {
    createCustomer(input: $input, password: $password) {
      ...Customer
      ...ErrorResult
    }
  }
  ${CUSTOMER_FRAGMENT}
  ${ERROR_RESULT_FRAGMENT}
`;

export const DELETE_CUSTOMER = gql`
  mutation DeleteCustomer($id: ID!) {
    deleteCustomer(id: $id) {
      result
      message
    }
  }
`;

export const SET_CUSTOMER_FOR_ORDER = gql`
  mutation setCustomerForOrder($orderId: ID!, $input: CreateCustomerInput!) {
    setCustomerForOrder(orderId: $orderId, input: $input) {
      ... on Order {
        id
        customer {
          id
        }
        couponCodes
      }
    }
  }
`;

export const SET_ORDER_BILLING_ADDRESS = gql`
  mutation setOrderBillingAddress($orderId: ID!, $input: CreateAddressInput!) {
    setOrderBillingAddress(orderId: $orderId, input: $input) {
      id
      couponCodes
    }
  }
`;

export const SET_ORDER_SHIPPING_ADDRESS = gql`
  mutation setOrderShippingAddress($orderId: ID!, $input: CreateAddressInput!) {
    setOrderShippingAddress(orderId: $orderId, input: $input) {
      id
      couponCodes
    }
  }
`;

export const SET_ORDER_CUSTOM_FIELDS = gql`
  mutation setOrderCustomFields($input: UpdateOrderInput!) {
    setOrderCustomFields(input: $input) {
      id
      customFields {
        complianceStatus
      }
      couponCodes
    }
  }
`;

export const TRANSITION_ORDER_TO_STATE = gql`
  mutation TransitionOrderToState($id: ID!, $state: String!) {
    transitionOrderToState(id: $id, state: $state) {
      ... on Order {
        id
        state
        customFields {
          complianceStatus
        }
      }
      ... on OrderStateTransitionError {
        errorCode
        message
      }
    }
  }
`;

export const TRANSITION_ORDER_TO_COMPLIANCE_SUCCESS = gql`
  mutation transitionOrderToComplianceSuccess($id: ID!) {
    transitionOrderToComplianceSuccess(id: $id) {
      ...Order
      ... on ErrorResult {
        errorCode
        message
      }
    }
  }
  ${ORDER_FRAGMENT}
`;

export const TRANSITION_ORDER_TO_COMPLIANCE_FAILED = gql`
  mutation transitionOrderToComplianceFailed($id: ID!) {
    transitionOrderToComplianceFailed(id: $id) {
      ...Order
      ... on OrderStateTransitionError {
        errorCode
        message
      }
    }
  }
  ${ORDER_FRAGMENT}
`;

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      ...Customer
      ...ErrorResult
    }
  }
  ${CUSTOMER_FRAGMENT}
  ${ERROR_RESULT_FRAGMENT}
`;

export const CREATE_ORDER = gql`
  mutation createOrder {
    createOrder {
      ...Order
    }
  }
  ${ORDER_FRAGMENT}
`;

export const UPDATE_CHANNEL = gql`
  mutation UpdateChannel($input: UpdateChannelInput!) {
    updateChannel(input: $input) {
      ...Channel
      ...ErrorResult
    }
  }
  ${CHANNEL_FRAGMENT}
  ${ERROR_RESULT_FRAGMENT}
`;

export const UPDATE_ACTIVE_ADMINISTRATOR = gql`
  mutation UpdateActiveAdministrator($input: UpdateActiveAdministratorInput!) {
    updateActiveAdministrator(input: $input) {
      ...Administrator
    }
  }
  ${ADMINISTRATOR_FRAGMENT}
`;

export const CREATE_ASSETS = gql`
  mutation CreateAssets($input: [CreateAssetInput!]!) {
    createAssets(input: $input) {
      ...Asset
      ... on Asset {
        tags {
          ...Tag
        }
      }
      ... on ErrorResult {
        message
      }
    }
  }
  ${ASSET_FRAGMENT}
  ${TAG_FRAGMENT}
`;

export const DELETE_ASSETS = gql`
  mutation DeleteAssets($input: DeleteAssetsInput!) {
    deleteAssets(input: $input) {
      result
      message
    }
  }
`;

export const CREATE_ADMINISTRATOR = gql`
  mutation CreateAdministrator($input: CreateAdministratorInput!) {
    createAdministrator(input: $input) {
      ...Administrator
    }
  }
  ${ADMINISTRATOR_FRAGMENT}
`;

export const UPDATE_ADMINISTRATOR = gql`
  mutation UpdateAdministrator($input: UpdateAdministratorInput!) {
    updateAdministrator(input: $input) {
      ...Administrator
    }
  }
  ${ADMINISTRATOR_FRAGMENT}
`;

export const CREATE_PRODUCT = gql`
  mutation CreateProduct($input: CreateProductInput!) {
    createProduct(input: $input) {
      ...ProductWithVariants
    }
  }
  ${PRODUCT_WITH_VARIANTS_FRAGMENT}
`;

export const ADD_PRODUCT_TO_COMPLIANCE = gql`
  mutation AddProductToCompliance($input: ProductComplianceInput!) {
    addProductToCompliance(input: $input)
  }
`;

export const EDIT_PRODUCT_TO_COMPLIANCE = gql`
  mutation EditProductToCompliance(
    $productKey: String!
    $input: ProductComplianceUpdateInput!
  ) {
    editProductToCompliance(productKey: $productKey, input: $input)
  }
`;

export const DELETE_PRODUCT_FROM_COMPLIANCE = gql`
  mutation DeleteProductFromCompliance(
    $productKey: String!
    $brandKey: String!
  ) {
    deleteProductFromCompliance(productKey: $productKey, brandKey: $brandKey)
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation UpdateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      ...ProductWithVariants
    }
  }
  ${PRODUCT_WITH_VARIANTS_FRAGMENT}
`;

export const DELETE_PRODUCT = gql`
  mutation DeleteProduct($id: ID!) {
    deleteProduct(id: $id) {
      result
    }
  }
`;

export const CREATE_PRODUCT_VARIANTS = gql`
  mutation CreateProductVariants($input: [CreateProductVariantInput!]!) {
    createProductVariants(input: $input) {
      ...ProductVariant
    }
  }
  ${PRODUCT_VARIANT_FRAGMENT}
`;

export const UPDATE_PRODUCT_VARIANTS = gql`
  mutation UpdateProductVariants($input: [UpdateProductVariantInput!]!) {
    updateProductVariants(input: $input) {
      ...ProductVariant
    }
  }
  ${PRODUCT_VARIANT_FRAGMENT}
`;

export const DELETE_PRODUCT_VARIANT = gql`
  mutation DeleteProductVariant($id: ID!) {
    deleteProductVariant(id: $id) {
      result
      message
    }
  }
`;

export const CREATE_PRODUCT_OPTION_GROUP = gql`
  mutation CreateProductOptionGroup($input: CreateProductOptionGroupInput!) {
    createProductOptionGroup(input: $input) {
      ...ProductOptionGroup
    }
  }
  ${PRODUCT_OPTION_GROUP_FRAGMENT}
`;

export const UPDATE_PRODUCT_OPTION = gql`
  mutation UpdateProductOption($input: UpdateProductOptionInput!) {
    updateProductOption(input: $input) {
      id
      code
      name
    }
  }
`;

export const UPDATE_PRODUCT_OPTION_GROUP = gql`
  mutation UpdateProductOptionGroup($input: UpdateProductOptionGroupInput!) {
    updateProductOptionGroup(input: $input) {
      ...ProductOptionGroup
    }
  }
  ${PRODUCT_OPTION_GROUP_FRAGMENT}
`;

export const ADD_OPTION_GROUP_TO_PRODUCT = gql`
  mutation AddOptionGroupToProduct($productId: ID!, $optionGroupId: ID!) {
    addOptionGroupToProduct(
      productId: $productId
      optionGroupId: $optionGroupId
    ) {
      ...ProductWithOptions
    }
  }
  ${PRODUCT_WITH_OPTIONS_FRAGMENT}
`;

export const REMOVE_OPTION_GROUP_FROM_PRODUCT = gql`
  mutation RemoveOptionGroupFromProduct($productId: ID!, $optionGroupId: ID!) {
    removeOptionGroupFromProduct(
      productId: $productId
      optionGroupId: $optionGroupId
    ) {
      ...ProductWithOptions
      ...ErrorResult
    }
  }
  ${PRODUCT_WITH_OPTIONS_FRAGMENT}
  ${ERROR_RESULT_FRAGMENT}
`;

export const ADD_NOTE_TO_CUSTOMER = gql`
  mutation AddNoteToCustomer($input: AddNoteToCustomerInput!) {
    addNoteToCustomer(input: $input) {
      id
    }
  }
`;

export const UPDATE_CUSTOMER_NOTE = gql`
  mutation UpdateCustomerNote($input: UpdateCustomerNoteInput!) {
    updateCustomerNote(input: $input) {
      id
      data
      isPublic
    }
  }
`;

export const DELETE_CUSTOMER_NOTE = gql`
  mutation DeleteCustomerNote($id: ID!) {
    deleteCustomerNote(id: $id) {
      result
      message
    }
  }
`;

export const CREATE_PROMOTION = gql`
  mutation CreatePromotion($input: CreatePromotionInput!) {
    createPromotion(input: $input) {
      ...Promotion
      ...ErrorResult
    }
  }
  ${PROMOTION_FRAGMENT}
  ${ERROR_RESULT_FRAGMENT}
`;

export const UPDATE_PROMOTION = gql`
  mutation UpdatePromotion($input: UpdatePromotionInput!) {
    updatePromotion(input: $input) {
      ...Promotion
    }
  }
  ${PROMOTION_FRAGMENT}
`;

export const DELETE_PROMOTION = gql`
  mutation DeletePromotion($id: ID!) {
    deletePromotion(id: $id) {
      result
      message
    }
  }
`;

export const CONFIGURABLE_OPERATION_DEF_FRAGMENT = gql`
  fragment ConfigurableOperationDef on ConfigurableOperationDefinition {
    args {
      name
      type
      required
      defaultValue
      list
      ui
      label
    }
    code
    description
  }
`;

export const ADD_OPTION_TO_GROUP = gql`
  mutation AddOptionToGroup($input: CreateProductOptionInput!) {
    createProductOption(input: $input) {
      id
      createdAt
      updatedAt
      name
      code
      groupId
    }
  }
`;

export const UDPATE_OPTION_TO_GROUP = gql`
  mutation UpdateOptionToGroup($input: UpdateProductOptionInput!) {
    updateProductOption(input: $input) {
      id
      createdAt
      updatedAt
      name
      code
      groupId
    }
  }
`;

export const CREATE_CLUB = gql`
  mutation CreateClub($input: CreateClubInput!) {
    createClub(input: $input) {
      id
      name
    }
  }
`;

export const UPDATE_CLUB = gql`
  mutation UpdateClub($input: UpdateClubInput!) {
    updateClub(input: $input) {
      ...Club
    }
  }
`;

export const DELETE_CLUB = gql`
  mutation DeleteClub($id: ID!) {
    deleteClub(id: $id) {
      result
    }
  }
`;

export const ADD_CUSTOMERS_TO_CLUB = gql`
  mutation AddCustomersToClub($clubId: ID!, $customerIds: [ID!]!) {
    addCustomersToClub(clubId: $clubId, customerIds: $customerIds) {
      id
      createdAt
      updatedAt
      name
    }
  }
`;

export const REMOVE_CUSTOMERS_FROM_CLUB = gql`
  mutation RemoveCustomersFromClub($clubCustomerIds: [ID!]!) {
    removeCustomersFromClub(clubCustomerIds: $clubCustomerIds) {
      result
      message
    }
  }
`;

export const CREATE_SEASON = gql`
  mutation CreateSeason($input: CreateSeasonInput!) {
    createSeason(input: $input) {
      id
      name
    }
  }
`;

export const UPDATE_SEASON = gql`
  mutation UpdateSeason($input: UpdateSeasonInput!) {
    updateSeason(input: $input) {
      id
      name
    }
  }
`;

export const DELETE_SEASON = gql`
  mutation DeleteSeason($id: ID!) {
    deleteSeason(id: $id) {
      result
    }
  }
`;

export const REMOVE_CUSTOMERS_FROM_SEASON = gql`
  mutation RemoveCustomerFromSeason($seasonId: ID!, $customerId: ID!) {
    removeCustomerFromSeason(seasonId: $seasonId, customerId: $customerId) {
      result
    }
  }
`;

export const ADD_CUSTOMERS_TO_SEASON = gql`
  mutation AddCustomerToSeason($seasonId: ID!, $customerId: ID!) {
    addCustomerToSeason(seasonId: $seasonId, customerId: $customerId)
  }
`;

export const PROCESS_SEASON = gql`
  mutation ProcessSeason($seasonId: ID!, $customerId: ID) {
    processSeason(seasonId: $seasonId, customerId: $customerId) {
      ...Season
    }
  }
  ${SEASON_FRAGMENT}
`;

export const UPDATE_CLUB_CUSTOMER = gql`
  mutation updateClubCustomer($input: UpdateClubCustomerInput!) {
    updateClubCustomer(input: $input) {
      cancelDate
      pauseDate
      status
    }
  }
`;

export const DELETE_CLUB_CUSTOMER = gql`
  mutation DeleteClubCustomer($id: ID!) {
    deleteClubCustomer(clubCustomerId: $id) {
      result
      message
    }
  }
`;

export const CREATE_CHANNEL = gql`
  mutation CreateChannel($input: CreateChannelInput!) {
    createChannel(input: $input) {
      ...Channel
    }
  }
  ${CHANNEL_FRAGMENT}
`;

export const GET_ROLE = gql`
  query GetRole($code: String!) {
    roleWithCode(code: $code) {
      ...Role
    }
  }
  ${ROLE_FRAGMENT}
`;

export const CREATE_ROLE = gql`
  mutation CreateRole($input: CreateRoleInput!) {
    createRole(input: $input) {
      ...Role
    }
  }
  ${ROLE_FRAGMENT}
`;

export const UPDATE_ROLE = gql`
  mutation UpdateRole($input: UpdateRoleInput!) {
    updateRole(input: $input) {
      ...Role
    }
  }
  ${ROLE_FRAGMENT}
`;

export const CREATE_COMPLIANCE = gql`
  mutation CreateCompliance($input: CreateComplianceInput!) {
    createCompliance(input: $input) {
      ...Compliance
    }
  }
  ${COMPLIANCE_FRAGMENT}
`;

export const UPDATE_COMPLIANCE = gql`
  mutation UpdateCompliance($input: UpdateComplianceInput!) {
    updateCompliance(input: $input) {
      ...Compliance
    }
  }
  ${COMPLIANCE_FRAGMENT}
`;

export const DELETE_COMPLIANCE = gql`
  mutation DeleteCompliance($id: ID!) {
    deleteCompliance(id: $id) {
      result
    }
  }
`;

export const CREATE_CUSTOMER_CARD = gql`
  mutation CreateCustomerCard(
    $customerEmail: String
    $paymentMethodId: String!
  ) {
    attachPaymentMethodToCustomer(
      customerEmail: $customerEmail
      paymentMethodId: $paymentMethodId
    ) {
      ... on Card {
        ...Card
      }
      ... on PaymentMethodAttachError {
        message
        errorCode
      }
    }
  }
  ${CARD_FRAGMENT}
`;

export const DELETE_CUSTOMER_CARD = gql`
  mutation DeleteCustomerCard($customerEmail: String!, $id: ID!) {
    deleteCustomerPaymentMethod(customerEmail: $customerEmail, id: $id) {
      ... on CardListDeleteResponse {
        isDeleted
      }
      ... on PaymentMethodDeleteError {
        message
      }
    }
  }
`;

export const ADD_MANUAL_PAYMENT_TO_ORDER = gql`
  mutation AddManualPayment($input: ManualPaymentInput!) {
    addManualPaymentToOrder(input: $input) {
      ...OrderDetail
      ...ErrorResult
    }
  }
  ${ORDER_DETAIL_FRAGMENT}
  ${ERROR_RESULT_FRAGMENT}
`;

export const CHECK_IF_COMPLIANCE = gql`
  mutation CheckIfCompliance($id: ID!) {
    checkifCompliance(stateID: $id)
  }
`;

export const SET_DEFAULT_COMPLIANCE = gql`
  mutation SetDefaultCompliance($channelId: ID) {
    setDefaultCompliance(channelId: $channelId)
  }
`;

export const CANCEL_ORDER = gql`
  mutation CancelOrder($input: CancelOrderInput!) {
    cancelOrder(input: $input) {
      ...OrderDetail
      ...ErrorResult
    }
  }
  ${ORDER_DETAIL_FRAGMENT}
  ${ERROR_RESULT_FRAGMENT}
`;

export const REFUND_ORDER = gql`
  mutation RefundOrder($input: RefundOrderInput!) {
    refundOrder(input: $input) {
      ...Refund
      ...ErrorResult
    }
  }
  ${REFUND_ORDER_FRAGMENT}
  ${ERROR_RESULT_FRAGMENT}
`;

export const CHECK_COMPLIANCE = gql`
  mutation CheckCompliance($stateCode: String!) {
    checkCompliance(stateCode: $stateCode) {
      ... on ComplianceCheck {
        compliance {
          id
          complianceAdvisory
          shippingAdvisory
        }
        status
      }
      ... on ComplianceCheckError {
        errorCode
        message
      }
    }
  }
`;
export const RUN_COMPLIANCE_CHECK_ADMIN = gql`
  mutation runComplianceCheckAdmin($input: ComplianceDataInput) {
    runComplianceCheckAdmin(input: $input) {
      ... on ComplianceQuoteData {
        ...ComplianceQuoteData
      }
      ... on ComplianceQuoteCheckError {
        errorCode
        message
      }
    }
  }
  ${COMPLIANCE_QUOTE_DATA_FRAGMENT}
`;

export const RE_RUN_COMPLIANCE_CHECK_ADMIN = gql`
  mutation reCheckComplianceAdmin($input: ComplianceDataInput) {
    reCheckCompliance(input: $input) {
      ... on ComplianceQuoteData {
        ...ComplianceQuoteData
      }
      ... on ComplianceQuoteCheckError {
        errorCode
        message
      }
    }
  }
  ${COMPLIANCE_QUOTE_DATA_FRAGMENT}
`;

export const SET_ORDERSHIPPING_METHOD = gql`
  mutation SetOrderShippingMethod($orderId: ID!, $shippingMethodId: ID!) {
    setOrderShippingMethod(
      orderId: $orderId
      shippingMethodId: $shippingMethodId
    ) {
      ...Order
    }
  }
  ${ORDER_DETAIL_FRAGMENT}
`;

export const APPLY_COUPON_CODE = gql`
  mutation ApplyCouponCode($orderId: ID!, $couponCode: String!) {
    applyCouponCode(orderId: $orderId, couponCode: $couponCode) {
      ...Order
      ... on ErrorResult {
        errorCode
        message
      }
    }
  }
  ${ORDER_DETAIL_FRAGMENT}
`;

export const REMOVE_COUPON_CODE = gql`
  mutation RemoveCouponCode($orderId: ID!, $couponCode: String!) {
    removeCouponCode(orderId: $orderId, couponCode: $couponCode) {
      ...Order
    }
  }
  ${ORDER_DETAIL_FRAGMENT}
`;

export const ADD_PAYMENT = gql`
  mutation AddPayment($orderId: ID!, $input: PaymentInput!) {
    addPaymentToOrder(orderId: $orderId, input: $input) {
      ...Cart
      ...ErrorResult
    }
  }
  ${ORDER_DETAIL_FRAGMENT}
  ${ERROR_RESULT_FRAGMENT}
`;

export const SET_SHIPPING_METHOD = gql`
  mutation SetShippingMethod($orderId: ID!, $shippingMethodId: ID!) {
    setOrderShippingMethod(
      orderId: $orderId
      shippingMethodId: $shippingMethodId
    ) {
      ...Order
    }
  }
  ${ORDER_DETAIL_FRAGMENT}
`;

export const SET_DEFAULT_CARD_ADMIN = gql`
  mutation SetDefaultCardAdmin($customerEmail: String, $id: ID!) {
    setDefaultCardAdmin(customerEmail: $customerEmail, id: $id) {
      ...Card
      ... on CardNotFoundError {
        message
      }
    }
  }
  ${CARD_FRAGMENT}
`;

export const CREATE_SHIPPING_METHOD = gql`
  mutation CreateShippingMethod($input: CreateShippingMethodInput!) {
    createShippingMethod(input: $input) {
      ...ShippingMethod
    }
  }
  ${SHIPPING_METHOD_FRAGMENT}
`;

export const UDPATE_SHIPPING_METHOD = gql`
  mutation UpdateShippingMethod($input: UpdateShippingMethodInput!) {
    updateShippingMethod(input: $input) {
      ...ShippingMethod
    }
  }
  ${SHIPPING_METHOD_FRAGMENT}
`;

export const DELETE_SHIPPING_METHOD = gql`
  mutation DeleteShippingMethod($id: ID!) {
    deleteShippingMethod(id: $id) {
      result
      message
    }
  }
`;

export const ASSIGN_PAYMENTS_TO_CHANNEL = gql`
mutation assignPaymentstoChannel($channelId: ID!){
  assignPaymentstoChannel(channelId:$channelId){
    ...PaymentMethod
  }
  ${PAYMENT_METHOD_FRAGMENT}
}
`;

export const ASSIGN_SHIPPINGS_TO_CHANNEL = gql`
  mutation assignShippingstoChannel($channelId: ID!) {
    assignShippingstoChannel(channelId: $channelId)
  }
`;

export const DELETE_ORDER_FROM_COMPLIANCE = gql`
  mutation DeleteOrderFromCompliance($code: String!) {
    deleteOrderFromCompliance(code: $code)
  }
`;

export const DELETE_CHANNEL = gql`
  mutation DeleteChannel($id: ID!) {
    deleteChannel(id: $id) {
      result
      message
    }
  }
`;

export const DELETE_ADMINISTRATOR = gql`
  mutation DeleteAdministrator($id: ID!) {
    deleteAdministrator(id: $id) {
      result
      message
    }
  }
`;

export const DELETE_ROLE = gql`
  mutation DeleteRole($id: ID!) {
    deleteRole(id: $id) {
      result
      message
    }
  }
`;

export const ADD_EMAIL_TEMPLATE = gql`
  mutation AddEmailTemplate($input: EmailTemplateAddInput!) {
    addEmailTemplate(input: $input) {
      ...EmailTemplate
    }
  }
  ${EMAIL_TEMPLATE_FRAGMENT}
`;

export const UPDATE_EMAIL_TEMPLATE = gql`
  mutation UpdateEmailTemplate($input: EmailTemplateUpdateInput!) {
    updateEmailTemplate(input: $input) {
      ...EmailTemplate
    }
  }
  ${EMAIL_TEMPLATE_FRAGMENT}
`;

export const DELETE_EMAIL_TEMPLATE = gql`
  mutation deleteEmailTemplate($id: ID!) {
    deleteEmailTemplate(id: $id)
  }
`;

export const ADD_TWF_SHIPPING_CSV = gql`
  mutation AddTwfShippingCSV($document: Upload!) {
    addTWFShippingCSV(document: $document)
  }
`;

export const ADD_ANNOUNCEMENT = gql`
  mutation AddAnnouncement($input: AnnouncementAddInput!) {
    addAnnouncement(input: $input) {
      ...Announcement
    }
  }
  ${ANNOUNCEMENT_FRAGMENT}
`;

export const UPDATE_ANNOUNCEMENT = gql`
  mutation UpdateAnnouncement($input: AnnouncementUpdateInput!) {
    updateAnnouncement(input: $input) {
      ...Announcement
    }
  }
  ${ANNOUNCEMENT_FRAGMENT}
`;

export const DELETE_ANNOUNCEMENT = gql`
  mutation DeleteAnnouncement($id: ID!) {
    deleteAnnouncement(id: $id)
  }
`;

export const ADD_PAGE_MENU = gql`
  mutation AddPageMenu($input: PageInput!) {
    createPage(input: $input) {
      ...PageMenu
    }
  }
  ${PAGE_MENU_FRAGMENT}
`;

export const UDPATE_PAGE_MENU = gql`
  mutation UpdatePageMenu($input: UpdatePageInput!) {
    updatePage(input: $input) {
      ...PageMenu
    }
  }
  ${PAGE_MENU_FRAGMENT}
`;

export const DELETE_PAGE_MENU = gql`
  mutation DeletePage($id: ID!) {
    deletePage(id: $id)
  }
`;

export const SEND_TEST_EMAIL = gql`
  mutation SendTestEmail($input: TestEmailInput!) {
    sendTestEmail(input: $input)
  }
`;

export const CREATE_CUSTOMER_IF_NOT_EXIST = gql`
  mutation CreateCustomerUserIfNotExist($id: ID!) {
    createCustomerUserIfNotExist(id: $id) {
      ...Customer
    }
  }
  ${CUSTOMER_FRAGMENT}
`;

export const CREATE_EMAIL_CATEGORY = gql`
  mutation CreateEmailCategory($input: EmailCategoryAddInput!) {
    createEmailCategory(input: $input) {
      ...EmailCategory
    }
  }
  ${EMAIL_CATEGORY_FRAGMENT}
`;

export const UPDATE_EMAIL_CATEGORY = gql`
  mutation UpdateEmailCategory($input: EmailCategoryUpdateInput!) {
    updateEmailCategory(input: $input) {
      ...EmailCategory
    }
  }
  ${EMAIL_CATEGORY_FRAGMENT}
`;

export const DELETE_EMAIL_CATEGORY = gql`
  mutation DeleteEmailCategory($id: ID!) {
    deleteEmailCategory(id: $id)
  }
`;

export const CREATE_DEFAULT_PICKUP_OPTIONS = gql`
  mutation CreateDefaultPickupOptions($id: ID) {
    createDefaultPickupOptions(channelID: $id)
  }
`;

export const UPDATE_PICKUP_OPTION = gql`
  mutation UpdatePickupOption($input: PickupOptionUpdateInput!) {
    updatePickupOption(input: $input) {
      ...PickupOption
    }
  }
  ${PICKUP_OPTION_FRAGMENT}
`;

export const CREATE_WINE_LABEL = gql`
  mutation CreateWineLabel($input: WineLabelAddInput!) {
    createWineLabel(input: $input) {
      id
      label
    }
  }
`;

export const DELETE_WINE_LABEL = gql`
  mutation DeleteWineLabel($id: ID!) {
    deleteWineLabel(id: $id)
  }
`;

export const CREATE_WINE_APPELLATION = gql`
  mutation CreateAppellation($input: AppellationAddInput!) {
    createAppellation(input: $input) {
      id
      appellation
    }
  }
`;

export const DELETE_WINE_APPELLATION = gql`
  mutation DeleteAppellation($id: ID!) {
    deleteAppellation(id: $id)
  }
`;

export const CREATE_WINE_VARIETAL = gql`
  mutation CreateVarietal($input: VarietalAddInput!) {
    createVarietal(input: $input) {
      id
      varietal
    }
  }
`;

export const DELETE_WINE_VARIETAL = gql`
  mutation DeleteVarietal($id: ID!) {
    deleteVarietal(id: $id)
  }
`;

export const CREATE_WINE_TYPE = gql`
  mutation CreateWineType($input: WineTypeAddInput!) {
    createWineType(input: $input) {
      id
      type
    }
  }
`;

export const DELETE_WINE_TYPE = gql`
  mutation DeleteWineType($id: ID!) {
    deleteWineType(id: $id)
  }
`;

export const DELETE_ALL_CHANNEL_ENTITIES = gql`
  mutation DeleteAllChannelEntities($id: ID!) {
    deleteAllChannelEntities(id: $id)
  }
`;

export const CREATE_DEFAULT_TEMPLATES = gql`
  mutation CreateDefaultTemplates($id: ID!) {
    createDefaultTemplates(id: $id)
  }
`;

export const CREATE_DEFAULT_PRODUCT_ATTRIBUTES = gql`
  mutation CreateDefaultProductAttributes($id: ID!) {
    createDefaultProductAttributes(id: $id)
  }
`;

export const CREATE_DEFAULT_POLICY = gql`
  mutation CreateDefaultPolicy($id: ID!) {
    createPolicyPage(id: $id)
  }
`;

export const PUSH_ORDER_TO_COMPLIANCE = gql`
  mutation PushOrderToShipCompliance($code: String!) {
    pushOrderToShipCompliance(code: $code)
  }
`;

export const CALCULATE_ORDER_TAX = gql`
  mutation CalculateOrderTax($id: ID!, $onSite: Boolean!, $zero: Boolean) {
    calculateOrderTax(id: $id, onSite: $onSite, zero: $zero)
  }
`;

export const REQUEST_ADMIN_PASSWORD_RESET = gql`
  mutation RequestAdminPasswordReset($emailAddress: String!) {
    requestAdminPasswordReset(emailAddress: $emailAddress) {
      ...Administrator
    }
  }
  ${ADMINISTRATOR_FRAGMENT}
`;

export const RESET_ADMIN_PASSWORD = gql`
  mutation ResetAdminPassword($token: String!, $password: String!) {
    resetAdminPassword(token: $token, password: $password) {
      ...Administrator
    }
  }
  ${ADMINISTRATOR_FRAGMENT}
`;

export const REFUND_ONLY_ORDER = gql`
  mutation RefundOnlyOrder($id: ID!) {
    refundOnlyOrder(id: $id)
  }
`;

export const CREATE_CHANNEL_SHIPPING_METHODS = gql`
  mutation CreateShippingsForChannel($id: ID!) {
    createShippingsForChannel(id: $id)
  }
`;

export const MANAGE_COMPLIANCE_STATE = gql`
  mutation ManageComplianceState($id: ID!) {
    manageComplianceState(id: $id)
  }
`;

export const UPDATE_SHIPPING_RATE = gql`
  mutation UpdateTWFShippingRate($input: ShippingStatePriceUpdate!) {
    updateTWFShippingRate(input: $input) {
      id
    }
  }
`;

export const ADJUST_STOCK_ALLOCATED = gql`
  mutation AdjustStockAllocated($input: AdjustStockAllocatedInput!) {
    adjustStockAllocated(input: $input)
  }
`;
